@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
